import React from "react";
import styled from "styled-components";
import JsonParser from "../../src/Utils/JsonParser";

class PdfOrderItem extends React.Component {
  render() {
    let { type, idx, order, shoesCustomTypes, css } = this.props;
    let { items } = JsonParser(order.data, { items: [] });

    return (
      <Wrapper idx={idx} css={css}>
        {type === "common_order" ? (
          <>
            <div className="stick">
              <div className="product-row">
                {shoesCustomTypes &&
                  shoesCustomTypes.map((type, idx) =>
                    this._getShoesOption(items, type.name)
                  )}
              </div>

              <div className="buyer-row">
                <div className="buyer-cell">
                  <div className="buyer-title">
                    <div className="buyer-title-text">Name/姓名</div>
                  </div>
                  <div className="buyer-value-text">
                    {order.buyer_name && order.buyer_name.slice(0, 9)}
                  </div>
                </div>
                <div className="buyer-cell">
                  <div className="buyer-title">
                    <div className="buyer-title-text">Phone/電話</div>
                  </div>
                  <div className="buyer-value-text">
                    {order.buyer_phone && order.buyer_phone.slice(1, 10)}
                  </div>
                </div>
                <div className="buyer-cell">
                  <div className="buyer-title" style={{ width: 68 }}>
                    <div className="buyer-title-text">Email/信箱</div>
                  </div>
                  <div className="email-text">
                    {order.buyer_email && order.buyer_email}
                  </div>
                </div>
              </div>

              <div className="order-row">
                <div className="order-cell">
                  <div className="order-title">
                    <div className="order-title-text">Remarks/備註</div>
                  </div>
                  <div className="order-value-text">
                    {order.production_note && order.production_note.slice(0, 9)}
                  </div>
                </div>
                <div className="order-cell">
                  <div className="order-title">
                    <div className="order-title-text">Lot No./批號</div>
                  </div>
                  <div className="order-value-text">
                    {order.title && order.title.slice(0, 9)}
                  </div>
                </div>
                <div className="order-cell">
                  <div className="order-title" style={{ width: 36 }}>
                    <div className="order-title-text" style={{ width: 80 }}>
                      Shipping/運送
                    </div>
                  </div>
                  <div className="order-value-text" style={{ width: 80 }}>
                    {order.are_goods_shipped ? "Y" : "N"}
                  </div>
                </div>
                <img className="qr-code" src="/images/qrCode.png" />
              </div>
            </div>
            <div className="note">
              <div className="note-text">Remarks/備註 :</div>
              <div
                className="note-text"
                style={{
                  position: "absolute",
                  top: 12
                }}
              >
                $ :
              </div>
            </div>
          </>
        ) : (
          <>
            {/* repair order */}
            <div className="stick">
              <div className="product-row">
                <div className="product-cell">
                  <div className="product-text">
                    {order.size && order.size.toUpperCase().slice(0, 3)}
                  </div>
                </div>
                <div className="product-cell">
                  <div className="product-text">
                    {order.shape && order.shape.toUpperCase().slice(0, 3)}
                  </div>
                </div>
                <div className="product-cell">
                  <div className="product-text">
                    {order.leather && order.leather.toUpperCase().slice(0, 3)}
                  </div>
                </div>
                <div className="product-cell">
                  <div className="product-text">
                    {order.bottom && order.bottom.toUpperCase().slice(0, 3)}
                  </div>
                </div>
                <div className="product-cell">
                  <div className="product-text">
                    {order.shoe_buckle &&
                      order.shoe_buckle.toUpperCase().slice(0, 3)}
                  </div>
                </div>
                <div className="product-cell">
                  <div className="product-text">
                    {order.belt && order.belt.toUpperCase().slice(0, 3)}
                  </div>
                </div>
              </div>

              <div className="buyer-row">
                <div className="buyer-cell">
                  <div className="buyer-title">
                    <div className="buyer-title-text">Name/姓名</div>
                  </div>
                  <div className="buyer-value-text">
                    {order.buyer_name && order.buyer_name.slice(0, 9)}
                  </div>
                </div>
                <div className="buyer-cell">
                  <div className="buyer-title">
                    <div className="buyer-title-text">Phone/電話</div>
                  </div>
                  <div className="buyer-value-text">
                    {order.buyer_phone && order.buyer_phone.slice(1, 10)}
                  </div>
                </div>
                <div className="buyer-cell">
                  <div className="buyer-title" style={{ width: 68 }}>
                    <div className="buyer-title-text">Email/信箱</div>
                  </div>
                  <div className="email-text">
                    {order.buyer_email && order.buyer_email}
                  </div>
                </div>
              </div>

              <div className="order-row">
                <div className="order-cell">
                  <div className="order-title">
                    <div className="order-title-text">Remarks/備註</div>
                  </div>
                  <div className="order-value-text">
                    {order.note && order.note.slice(0, 9)}
                  </div>
                </div>
                <div className="order-cell">
                  <div className="order-title">
                    <div className="order-title-text">Lot No./批號</div>
                  </div>
                  <div className="order-value-text">
                    {order.serial_number && order.serial_number.slice(0, 9)}
                  </div>
                </div>
                <div className="order-cell">
                  <div className="order-title" style={{ width: 36 }}>
                    <div className="order-title-text" style={{ width: 80 }}>
                      Shipping/運送
                    </div>
                  </div>
                  <div className="order-value-text" style={{ width: 80 }}>
                    {order.are_goods_shipped && order.are_goods_shipped
                      ? "Y"
                      : "N"}
                  </div>
                </div>
                <img className="qr-code" src="/images/qrCode.png" />
              </div>
            </div>
            <div className="note">
              <div className="note-text">Remarks/備註 :</div>
              <div className="note-text">$ :</div>
            </div>
          </>
        )}
      </Wrapper>
    );
  }

  _getShoesOption = (items, type) => {
    if (!items) {
      return;
    }
    return items[0].choices
      .filter(c => c.type.name === type)
      .map((f, i) => (
        <div className="product-cell">
          <div className="product-text">
            {f.option.name.toUpperCase().slice(0, 3)}
          </div>
        </div>
      ));
  };
}

const Wrapper = styled.div`
  position: relative;
  border-right: ${props => (props.idx % 2 === 0 ? "1px solid #737987" : "0px")};
  border-bottom: ${props =>
    props.idx === 6 || props.idx === 7 ? "0px" : "1px solid #737987"};
  padding: 5.2px;
  width: 222.4px;
  height: 137.5px;

  & .stick {
  }

  & .note {
    position: absolute;
    left: 0px;
    bottom: 0px;
    border-top: 1px solid lightgray;
    width: 222.4px;
    height: 37.5px;
    padding: 2px 5.2px;

    & > .note-text {
      font-size: 12px;
      -webkit-transform-origin-x: 0;
      -webkit-transform: scale(0.4);
    }
  }

  & .product-row {
    margin-bottom: 5px;
    width: 212px;
    display: flex;
  }

  & .product-cell {
    width: 35px;
    height: 20px;
    border-top: 1px solid #737987;
    border-bottom: 1px solid #737987;
    border-left: 1px solid #737987;
    padding: 0px 2px;

    & :last-child {
      border-right: 1px solid #737987;
    }
  }

  & .product-text {
    width: 38px;
    text-align: center;
    font-weight: 600;
    font-size: 10px;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.8);
  }

  & .buyer-row {
    margin-bottom: 5px;
    width: 212px;
    display: flex;
  }

  & .buyer-cell {
    width: 70px;
    height: 25px;
    border-top: 1px solid #737987;
    border-bottom: 1px solid #737987;
    border-left: 1px solid #737987;

    text-align: center;
    font-weight: 200;
    font-size: 10px;

    & :last-child {
      border-right: 1px solid #737987;
    }
  }

  & .buyer-title {
    width: 70px;
    height: 8px;
    border-bottom: 1px solid #737987;
    padding: 2px;
  }

  & .buyer-title-text {
    position: absolute;
    top: 25px;
    padding-top: 1px;
    width: 150px;
    font-weight: 400;
    font-size: 12px;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.4);
  }

  & .buyer-value-text {
    position: absolute;
    top: 34px;
    font-size: 18px;
    width: 150px;
    text-align: center;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.4);
  }

  & .email-text {
    position: absolute;
    top: 34px;
    padding-left: 5px;
    font-size: 18px;
    width: 220px;
    text-align: center;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.3);
  }

  & .order-row {
    width: 212px;
    display: flex;
    align-items: center;
  }

  & .order-cell {
    width: 70px;
    height: 30px;
    border-top: 1px solid #737987;
    border-bottom: 1px solid #737987;
    border-left: 1px solid #737987;

    text-align: center;
    font-weight: 200;
    font-size: 10px;

    & :nth-child(3) {
      margin-right: 5px;
      width: 37px;
      border-right: 1px solid #737987;
    }
  }

  & .order-title {
    width: 70px;
    height: 8px;
    border-bottom: 1px solid #737987;
    padding: 2px;
  }

  & .order-title-text {
    position: absolute;
    top: 55px;
    padding-top: 1px;
    width: 150px;
    font-weight: 400;
    font-size: 12px;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.4);
  }

  & .order-value-text {
    position: absolute;
    top: 67px;
    font-size: 18px;
    width: 150px;
    text-align: center;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.4);
  }

  & .qr-code {
    width: 30px;
    height: 30px;
  }

  ${props => props.css || ""};
`;

export default PdfOrderItem;
