import React, { Component } from "react";
import styled from "styled-components";
import * as Widget from "./Widget";

class PdfFactoryOrderNote extends Component {
  render() {
    let { type, data, css } = this.props;

    return (
      <Wrapper css={css}>
        <div className="basic-container">
          <div className="text">Chenjingkai Office</div>
          <div className="text">工廠單編號：{data.title}</div>
          <div className="text">
            {type === "common_order" ? "訂單" : "維修單"}
          </div>
        </div>
        <div className="form-container">
          <div className="label">Tabulation Date :</div>
          <div className="label">Dilivery Date :</div>
        </div>
        <div className="form-container">
          <div className="label">Page Total :</div>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  border-top: 1px solid #737987;
  padding: 5.2px;
  width: 444.8px;
  height: 80.6px;

  & .basic-container {
    max-width: 150px;
    width: 100%;
    padding-right: 5px;
  }

  & .form-container {
    flex: 1;
  }

  & .text {
    font-size: 12px;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.6);
  }

  & .label {
    font-size: 12px;
    -webkit-transform-origin-x: 0;
    -webkit-transform: scale(0.4);
  }

  ${props => props.css || ""};
`;

export default PdfFactoryOrderNote;
