export function ordersMultisort(arr, columns, order_by) {
  if (typeof columns == "undefined") {
    columns = [];
    for (x = 0; x < arr[0].length; x++) {
      columns.push(x);
    }
  }

  if (typeof order_by == "undefined") {
    order_by = [];
    for (x = 0; x < arr[0].length; x++) {
      order_by.push("ASC");
    }
  }

  function multisort_recursive(a, b, columns, order_by, index) {
    let direction = order_by[index] == "DESC" ? 1 : 0;
    let is_numeric = !isNaN(a[columns[index]] - b[columns[index]]);
    let x = is_numeric
      ? a[columns[index]]
      : (a[columns[index]] || "").toLowerCase();
    let y = is_numeric
      ? b[columns[index]]
      : (b[columns[index]] || "").toLowerCase();

    function to_ascii(s) {
      return s.charCodeAt(0);
    }

    if (!is_numeric) {
      (x = to_ascii((a[columns[index]] || "").toLowerCase(), -1)),
        (y = to_ascii((b[columns[index]] || "").toLowerCase(), -1));
    }

    if (x < y) {
      return direction == 0 ? -1 : 1;
    }

    if (x == y) {
      return columns.length - 1 > index
        ? multisort_recursive(a, b, columns, order_by, index + 1)
        : 0;
    }

    return direction == 0 ? 1 : -1;
  }

  return arr.sort(function(a, b) {
    return multisort_recursive(a, b, columns, order_by, 0);
  });
}

// let ranking = RepairOrdersMultisort(instance.repair_orders, ['shape', 'size', 'leather'], ['ASC','ASC', 'ASC',]);
