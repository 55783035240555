import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import ActionCreator from "../../ActionCreator";
import { withPage } from "../../Page";
import { ordersDataConvert } from "../../../src/Utils/OrdersDataConvertUtil";
import { ordersMultisort } from "../../../src/Utils/OrdersMutiSortUtil";
import * as Widget from "../../Components/Widget";
import PdfOrderItem from "../../Components/BuffetPdfOrderItem";
import PdfFactoryOrderNote from "../../Components/BuffetPdfFactoryOrderNote";

const delay = ms => new Promise((resolve, reject) => setTimeout(resolve, ms));

class PreviewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      instance: null,
      shoesCustomTypes: [],
      domElementArray: [],
      showSpinner: true,
      showDownloadSpinner: false
    };
  }

  componentDidMount() {
    let id = this._getFactoryOrderId(window.location.search);
    this._fetchFactroyOrderById(id);
    this._getShoesCustomType();
  }

  render() {
    let {
      instance,
      shoesCustomTypes,
      domElementArray,
      showSpinner,
      showDownloadSpinner
    } = this.state;

    return (
      <Wrapper>
        <div className="actions-bar">
          {showDownloadSpinner ? (
            <Widget.Row justify="center" align="center">
              <Widget.Spinner />
            </Widget.Row>
          ) : (
            <div
              className="button"
              onClick={() =>
                this._downloadPDF(domElementArray, "download.pdf", 446.8, 631.6)
              }
            >
              下載
            </div>
          )}
        </div>
        {showSpinner ? (
          <PreviewContainer>
            <Widget.Spinner />
          </PreviewContainer>
        ) : (
          <PreviewContainer>
            {this._getPageNumArray(instance.orders).map((_, index) => (
              <PageContainer
                type="common_order"
                index={index}
                key={"page" + index}
                css="margin-bottom: 30px;"
              >
                <div id={`pdf-common_order-page-${index}`}>
                  {instance.orders
                    .filter((_, idx) => Math.floor(idx / 8) === index)
                    .map((o, i) => (
                      <PdfOrderItem
                        key={"common_order" + i}
                        type="common_order"
                        idx={i}
                        order={o}
                        shoesCustomTypes={shoesCustomTypes}
                        css={`
                          position: absolute;
                          top: ${this._getTopPosition(i)}px;
                          left: ${i % 2 === 0 ? 0 : 222.4}px;
                        `}
                      />
                    ))}
                  <PdfFactoryOrderNote
                    type="common_order"
                    data={instance}
                    css={`
                      position: absolute;
                      left: 0px;
                      bottom: 0px;
                    `}
                  />
                </div>
              </PageContainer>
            ))}

            {this._getPageNumArray(instance.repair_orders).map((_, index) => (
              <PageContainer
                type="repair_order"
                index={index}
                key={"page" + index}
                css="margin-bottom: 30px;"
              >
                <div id={`pdf-repair_order-page-${index}`}>
                  {instance.repair_orders
                    .filter((_, idx) => Math.floor(idx / 8) === index)
                    .map((o, i) => (
                      <PdfOrderItem
                        type="repair_order"
                        idx={i}
                        order={o}
                        key={"repair_order" + i}
                        css={`
                          position: absolute;
                          top: ${this._getTopPosition(i)}px;
                          left: ${i % 2 === 0 ? 0 : 222.4}px;
                        `}
                      />
                    ))}
                  <PdfFactoryOrderNote
                    type="repair_order"
                    data={instance}
                    css={`
                      position: absolute;
                      left: 0px;
                      bottom: 0px;
                    `}
                  />
                </div>
              </PageContainer>
            ))}
          </PreviewContainer>
        )}
      </Wrapper>
    );
  }

  _getTopPosition = i => {
    if (i === 0 || i === 1) {
      return 0 * 137.5;
    } else if (i === 2 || i === 3) {
      return 1 * 137.5;
    } else if (i === 4 || i === 5) {
      return 2 * 137.5;
    } else if (i === 6 || i === 7) {
      return 3 * 137.5;
    }
  };

  _getPageNumArray = orders => {
    let pageNum = Math.ceil(orders.length / 8);
    let pageNumArray = [];

    for (let i = 0; i < pageNum; i++) {
      pageNumArray.push(i);
    }
    return pageNumArray;
  };

  _getFactoryOrderId = path => {
    let index = path.indexOf("=");
    return path.slice(index + 1);
  };

  _fetchFactroyOrderById = id => {
    let { appActions } = this.props;

    delay(200)
      .then(() => appActions.getFactoryOrderById(id))
      .then(instance => {
        let convertCommonOrders = ordersDataConvert(instance.orders);
        let sortCommonOrders = ordersMultisort(
          convertCommonOrders,
          ["shape", "size", "leather"],
          ["ASC", "ASC", "ASC"]
        );
        let sortRepairOrders = ordersMultisort(
          instance.repair_orders,
          ["shape", "size", "leather"],
          ["ASC", "ASC", "ASC"]
        );
        this.setState({
          instance: {
            ...instance,
            repair_orders: sortRepairOrders,
            orders: sortCommonOrders
          }
        });

        let commonOrderPageNum = Math.ceil(instance.orders.length / 8);
        let repairOrderPageNum = Math.ceil(instance.repair_orders.length / 8);
        let result = [];
        for (let i = 0; i < commonOrderPageNum; i++) {
          result.push(`pdf-common_order-page-${i}`);
        }
        for (let i = 0; i < repairOrderPageNum; i++) {
          result.push(`pdf-repair_order-page-${i}`);
        }
        this.setState({ domElementArray: result });
      })
      .then(() => this.setState({ showSpinner: false }))
      .catch(err => {
        console.warn(err);
        this.setState({ showSpinner: false });
      });
  };

  _getShoesCustomType = async () => {
    let { appActions } = this.props;

    try {
      const shoesCustomTypes = await appActions.getShoesCustomType();

      this.setState({ shoesCustomTypes });
    } catch (ex) {
      console.warn(ex);
    }
  };

  _downloadPDF = async (
    domElementArray,
    outputFilename,
    w,
    h,
    x = 0,
    y = 0,
    pdfConfig = { orientation: "portrait", unit: "px" }
  ) => {
    this.setState({ showDownloadSpinner: true });

    if (!window.html2canvas || !window.jsPDF) {
      console.warn("html2canvas not loaded yet or not included");
      return;
    }

    let pdf = new jsPDF(pdfConfig);
    for (let i = 0; i < domElementArray.length; i++) {
      let element = document.getElementById(domElementArray[i]);
      let canvas = await window.html2canvas(element, { scale: 5 });
      pdf.addImage(canvas.toDataURL("image/png"), "PNG", x, y, w, h);
      if (i <= domElementArray.length - 2) {
        pdf.addPage();
      }
    }

    pdf.save(outputFilename);
    this.setState({ showDownloadSpinner: false });
  };
}

const PreviewContainer = styled.div`
  position: absolute;
  top: 70px;
  left: 0px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 10px;
  width: 100%;
  min-height: calc(100vh - 70px);
  background: #f9f9f9;

  & > .page-container {
    margin-bottom: 30px;
  }

  & #pdf-page {
    width: 446.8px;
    height: 631.6px;
    border: 1px solid #737987;
    background: white;

    position: relative;
  }
`;

const PageContainer = styled.div`

  & #pdf-${props => props.type}-page-${props => props.index} {
    width: 446.8px;
    height: 631.6px;
    border: 1px solid #f9f9f9;
    background: white;

    position: relative;
  }

  ${props => props.css || ""};
`;

const Wrapper = styled.div`
  position: relative;

  & > .actions-bar {
    position: fixed;
    top: 0px;
    left: 0px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    min-height: 70px;
    background: #8596a6;
    padding: 10px 15px;

    & > .button {
      display: flex;
      justify-content: center;
      align-items: center;

      max-width: 120px;
      width: 100%;
      height: 40px;
      letter-spacing: 1.6px;

      cursor: pointer;
      background: #ffffff;
      letter-spacing: 1.6px;
      font-weight: 400;
      font-size: 16px;
      color: black;
    }
  }
`;

export default withPage(
  connect(
    null,
    ActionCreator
  )(PreviewPage)
);
