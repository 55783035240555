import JsonParser from "./JsonParser";

export function ordersDataConvert(orders) {
  let convertResults = orders.map(o => {
    let result = { ...o };
    let raw = JsonParser(o.data, { items: [] });

    raw = raw.items[0].choices.map(c => {
      if (c.type.name === "size") {
        return (result.size = c.option.name);
      } else if (c.type.name === "shape") {
        return (result.shape = c.option.name);
      } else if (c.type.name === "leather") {
        return (result.leather = c.option.name);
      } else if (c.type.name === "bottom") {
        return (result.bottom = c.option.name);
      } else if (c.type.name === "shoebuckle") {
        return (result.shoebuckle = c.option.name);
      } else if (c.type.name === "belt") {
        return (result.belt = c.option.name);
      }
    });
    return result;
  });
  return convertResults;
}
